<template>
  <v-container fluid>
    <ShopTabs />
    <v-row>
      <v-col cols="9">
        <div class="d-flex justify-space-between align-center gap-x-2 mb-3">
          <h3 class="font-semibold text-blue">
            Available Packages
          </h3>
          <v-text-field
              v-model="search"
              class="search"
              clearable
              dense
              flat
              hide-details="auto"
              label="Search"
              solo
              @keyup.enter="searchOffer"
              @click:append="searchOffer"
              @click:clear="clearSearch"
          >
            <template #prepend-inner>
              <SearchIcon/>
            </template>
          </v-text-field>
        </div>
        <v-row>
          <v-col v-for="offer in offers" :key="offer.id" lg="12" md="12" sm="12">
            <PackageCard :offer="offer"/>
          </v-col>

          <v-pagination v-model="page" :length="totalPages" class="new-pagination mx-auto" total-visible="7"></v-pagination>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShopTabs from "@/views/Shop/ShopTabs.vue";
import PackageCard from "@/components/Shop/Offers/PackageCard.vue";
import SearchIcon from "@/assets/images/misc/search.svg";

export default {
  name: "ShopOffers",
  components: {SearchIcon, PackageCard, ShopTabs},
  mounted() {
    this.getAllOffers();
  },
  watch: {
    reloadShopModule(val){
      if(val && val === 'offers'){
        this.getAllOffers();
      }
    }
  },
  computed: {
    reloadShopModule(){
      return this.$store.getters.getReloadShopModule;
    },
    getCurrentVenueId(){
      return this.$store.getters.svid;
    },

  },
  methods: {
    getAllOffers() {
      this.offers = [];
      const venueId = this.getCurrentVenueId;
      this.showLoader('Loading Offers');
      this.$http
          .get(`venues/shop/offers?venueId=${venueId}`,{
            params: {
              page: this.page,
              search: this.search,
            }
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data
              this.offers = data.data;
              this.totalPages = data.last_page;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(() => {
            this.hideLoader();
          });
    },
    clearSearch() {
      this.search = "";
      this.getAllOffers();
    },
    searchOffer() {
      this.page = 1
      this.getAllOffers();
    }
  },
  data() {
    return {
      offers: [],
      search: "",
      totalPages: 1,
      page: 1
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .search {
  border-radius: 4px;
  border: 1px solid #EFEDED;
  background: #FFF;
  max-width: 250px;

  .v-label {
    opacity: 0.3;
  }
}
</style>